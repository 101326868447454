<template>
  <div class="constitution-details app-container">
    <div class="title">{{mainData.Title}}</div>
    <div class="time">时间：{{mainData.Year}}年</div>
    <div class="content" v-html="mainData.Content"></div>
  </div>
</template>

<script>
  import API from '@/api/config'
  export default {
    name: "constitution-details",
    data(){
      return{
        mainData: {},
        query:{
          Id: this.$route.query.Id
        }
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      //initialize data
      initialize(){
        this.getConstitutionInfo()
      },
      //get info
      getConstitutionInfo(){
        API.School.SchoolConstitutionInfo(this.query).then(res => {
          this.mainData = res.data
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .constitution-details{
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    .title {
      font-size: 24px;
      color: var(--text-font-color);
      text-align: center;
    }
    .time {
      margin: 1rem;
      font-size: 13px;
      color: var(--text-font-color);
      text-align: center;
    }
  }

</style>
